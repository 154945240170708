import { gsap } from 'gsap';

export default class Ticker {
    private $el: HTMLImageElement;
    private $inner: HTMLElement;
    private direction: -1 | 1;
    private dragStartX: number;
    private moveToX: number;
    private nodeWidthFirst: number;
    private nodeWidthLast: number;
    private inertia: number;
    private isDraggable: boolean;
    private isDragging: boolean;
    private isPauseOnHover: boolean;
    private isVisible: boolean;
    private nodes: Node[];
    private onDragRef: (e: PointerEvent) => void;
    private onDragStopRef: () => void;
    private speed: number;
    private speedRestore: number;
    private translateX: number;

    constructor($el: HTMLImageElement) {
        this.$el = $el;
        this.$inner = this.$el.querySelector('.js-tickerInner')!;
        this.dragStartX = 0;
        this.inertia = 0.1;
        this.isDraggable = true;
        this.isDragging = false;
        this.isVisible = true;
        this.moveToX = 0;
        this.nodes = [];
        this.nodeWidthFirst = 0;
        this.nodeWidthLast = 0;
        this.onDragRef = this.onDrag.bind(this);
        this.onDragStopRef = this.onDragStop.bind(this);
        this.translateX = 0;

        // Settings
        const draggable = this.$el.dataset.tickerDraggable ?? 'true';
        const isTouchDevice = 'ontouchstart' in document.documentElement;

        if (draggable == 'false') {
            this.isDraggable = false;
        }

        this.$el.classList.toggle('is-draggable', this.isDraggable);
        this.direction = this.$el.dataset.tickerDirection === 'right' ? 1 : -1;
        this.isPauseOnHover = this.$el.dataset.tickerPauseOnHover !== 'false';
        this.speed = parseFloat(this.$el.dataset.tickerSpeed ?? '0.5');
        this.speedRestore = this.speed;

        if (this.$inner && this.$inner.childNodes.length) {
            // Remove all non-element nodes
            this.nodes = Array.from(this.$inner.childNodes).filter(($node) => $node.nodeType === Node.ELEMENT_NODE);
            this.$inner.innerHTML = '';
            this.$inner.append(...this.nodes);

            this.initListeners();
            this.update();
            this.animate();
        }
    }

    animate(): void {
        requestAnimationFrame(this.animate.bind(this));

        if (!this.isVisible) {
            return;
        }

        if (!this.isDragging) {
            this.moveToX += this.speed * this.direction;
        }

        this.translateX += (this.moveToX - this.translateX) * this.inertia;

        if (this.translateX > 0) {
            this.translateX -= this.nodeWidthLast;
            this.moveToX -= this.nodeWidthLast;
            this.dragStartX += this.nodeWidthLast;

            if (this.$inner.lastChild) {
                this.$inner.insertBefore(this.$inner.lastChild, this.$inner.firstChild);
                this.updateNodeWidths();
            }
        }

        if (this.translateX < -this.nodeWidthFirst) {
            this.translateX += this.nodeWidthFirst;
            this.moveToX += this.nodeWidthFirst;
            this.dragStartX -= this.nodeWidthFirst;

            if (this.$inner.firstChild) {
                this.$inner.appendChild(this.$inner.firstChild);
                this.updateNodeWidths();
            }
        }

        this.$inner.style.transform = `translateX(${this.translateX}px)`;
    }

    initListeners(): void {
        // Intersection observer
        const intersectionObserver = new IntersectionObserver(([entry]) => {
            this.isVisible = entry.isIntersecting;
        });
        intersectionObserver.observe(this.$el);

        // Resize observer
        const resizeObserver = new ResizeObserver(([entry]) => {
            this.update();
        });
        resizeObserver.observe(this.$el);

        this.$el.querySelectorAll('img').forEach(($img) => {
            $img.addEventListener('load', () => {
                this.update();
            });
        });

        window.addEventListener('load', () => {
            this.update();
        });

        if (this.isDraggable) {
            this.$el.addEventListener('pointerdown', (e) => {
                this.onDragStart(e);
                document.addEventListener('pointermove', this.onDragRef, false);
                document.addEventListener('pointercancel', this.onDragStopRef, false);
                document.addEventListener('pointerup', this.onDragStopRef, false);
            });
        }

        if (this.isPauseOnHover) {
            this.$el.addEventListener('mouseout', () =>
                gsap.to(this, {
                    duration: 1,
                    ease: 'power2.out',
                    speed: this.speedRestore,
                })
            );

            this.$el.addEventListener('mouseover', () =>
                gsap.to(this, {
                    duration: 1,
                    ease: 'power2.out',
                    speed: 0,
                })
            );
        }
    }

    onDrag(e: PointerEvent): void {
        if (this.dragStartX) {
            this.moveToX = e.pageX - this.dragStartX;
        }
    }

    onDragStart(e: PointerEvent): void {
        this.$el.classList.add('is-grabbing');
        this.dragStartX = e.pageX - this.translateX;
        this.isDragging = true;
    }

    onDragStop(): void {
        this.$el.classList.remove('is-grabbing');
        this.isDragging = false;

        document.removeEventListener('pointermove', this.onDragRef, false);
        document.removeEventListener('pointercancel', this.onDragStopRef, false);
        document.removeEventListener('pointerup', this.onDragStopRef, false);
    }

    update(): void {
        this.$inner.innerHTML = '';
        this.$inner.append(...this.nodes);

        requestAnimationFrame(() => {
            this.updateNodeWidths();

            const minWidth = this.$el.offsetWidth + (this.direction === -1 ? this.nodeWidthFirst : this.nodeWidthLast);
            const width = this.$inner.offsetWidth;
            const repeat = Math.ceil(minWidth / width) - 1;

            for (let i = 0; i < repeat; i++) {
                this.nodes.forEach(($node) => {
                    this.$inner.appendChild($node.cloneNode(true));
                });
            }
        });
    }

    updateNodeWidths(): void {
        if (this.$inner.firstChild instanceof HTMLElement) {
            this.nodeWidthFirst = this.$inner.firstChild.offsetWidth;
        }

        if (this.$inner.lastChild instanceof HTMLElement) {
            this.nodeWidthLast = this.$inner.lastChild.offsetWidth;
        }
    }
}
